<template>
    <ion-page>
        <ion-header>
            <ion-toolbar
                style="--background: #03a9f4; color: white; --min-height: 36px"
                class="custom-toolbar"
            >
                <div class="row align-center">
                    <slot name="before">
                        <div class="shrink">
                            <DxButton
                                type="default"
                                icon="mdi mdi-chevron-left"
                                class="ml-1"
                                @click="$router.go(-1)"
                            />
                        </div>
                    </slot>
                    <slot name="center">
                        <div class="grow text-xs-center">DANH SÁCH XE</div>
                    </slot>
                    <slot name="after">
                        <div class="shrink">
                            <DxButton
                                type="default"
                                icon="mdi mdi-plus-circle"
                                class="mr-1"
                                @click="$router.push('/Thong-Tin-Xe')"
                            /></div
                    ></slot>
                </div>
            </ion-toolbar>
        </ion-header>
        <ion-content :scroll-events="false">
            <div class="row mb-1 px-2 mt-2">
                <div class="xs12">
                    <DxTextBox
                        v-model="TextTimKiem"
                        mode="text"
                        placeholder="Nhập để tìm kiếm (Biển số,...)"
                        styling-mode="outlined"
                        validationMessageMode="always"
                        :height="36"
                        valueChangeEvent="keyup"
                    />
                </div>
            </div>
            <DataListVue :DanhSachXe="DanhSachXe" @getData="LayDanhSachXe" />
        </ion-content>
    </ion-page>
</template>
<script>
import { DxTextBox } from "devextreme-vue/text-box";
import {
    IonHeader,
    IonToolbar,
    IonContent,
    IonPage,
    onIonViewWillLeave,
    onIonViewWillEnter,
} from "@ionic/vue";
import { DxButton } from "devextreme-vue";
import DataListVue from "./components/DataList.vue";
export default {
    components: {
        DxTextBox,
        IonHeader,
        IonToolbar,
        IonContent,
        IonPage,
        DxButton,
        DataListVue,
    },
    data() {
        return {
            DanhSachXe: [],
            TextTimKiem: "",
        };
    },
    computed: {},
    watch: {
        TextTimKiem(value) {
            setTimeout(() => {
                if (value == this.TextTimKiem) {
                    this.LayDanhSachXe();
                }
            }, 1100);
        },
    },
    methods: {
        async LayDanhSachXe() {
            try {
                this.DanhSachXe = (
                    await this.$store.dispatch(
                        "ThongTinPhuongTien/Reload_DanhSachPhuongTien",
                        {
                            TextTimKiem: this.TextTimKiem,
                        },
                    )
                ).Store;
            } catch (ex) {
                console.log("🚀 ex", ex);
            }
        },
    },
    created() {
        onIonViewWillEnter(() => {
            this.LayDanhSachXe();
        });
    },
    async mounted() {
        onIonViewWillLeave(() => {});
    },
};
</script>

<style scoped>
:deep(.dx-texteditor.dx-editor-outlined .dx-placeholder::before) {
    padding: 4px 16px 0px;
}
</style>
